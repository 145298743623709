.navbar {
    background-color: #8DECB4;
    border-bottom: 1px solid #e9ecef;
    padding: 0px 10%;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.navbar-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #141E46;
    text-decoration: none;
}

.nav-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
}

.nav-item {
    margin: 0 10px;
}

.nav-link {
    color: #141E46;
    text-decoration: none;
    font-size: 14px;
}

.nav-list-mobile {
    display: none;
}

.menu-button {
    display: none;
}

.nav-logout-btn {
    background-color: #141E46;
    color: #FFF7FC;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;

}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 0px 5%;
    }

    .navbar-heading {
        font-size: 1.2rem;
    }

    .nav-list {
        display: none;
    }


    .nav-list-mobile {
        display: block;
        position: fixed;
        top: 10vh;
        color: black;
        background-color: #8DECB4;
        padding: 9px 20px;
        list-style: none;
        z-index: 1000;
        width: 100%;
    }

    .nav-item {
        margin: 10px 0;
    }
    
    .menu-button {
        display: block;
        background-color: transparent;
        padding: 0px;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu-icon {
        color: #141E46;
        font-size: 24px;
    }
}
.exam-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    margin-top: 10vh;
    /* padding: 25px 10%; */
}

.exam-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    background-color: #FFF5E0;
    padding: 10px 4%;
}

.exam-title {
    font-size: 22px;
    font-weight: 600;
    color: #141E46;
}

.exam-questions-attempt-con {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.exam-questions-attempted {
    font-size: 13px;
    color: #141E46;
    margin-bottom: 10px;
}

.exam-end-button {
    color: #41B06E;
    padding: 0px;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
}

.exam-end-icon {
    margin-right: 5px;
    font-size: 14px;
}

.exam-end-text {
    font-size: 13px;
    font-weight: 600;
}

.exam-time {
    font-size: 20px;
    color: #141E46;
    font-weight: 600;
}

.exam-questions-list {
    width: 80%;
    max-width: 800px;
    background-color: #f9f4e9;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 10px 0px #00000050;
    display: flex;
    flex-direction: column;
    list-style-type: none;
}

.exam-question-text {
    font-size: 17px;
    color: #141E46;
    font-weight: 500;
    margin-bottom: 10px;
}

.exam-answers {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    list-style-type: none;
    margin-top: 10px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #141E46;
    margin: 20px 0px;
}

.exam-answer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.answer {
    margin-right: 10px;
    height: 15px;
    width: 15px;
    border-color: #141E46;
}

.answer-label {
    font-size: 16px;
    color: #141E46;
    font-weight: 500;
}

.exam-question-buttons-con {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.exam-question-button {
    padding: 8px 16px;
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    font-weight: 500;
}

.submit-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-popup-overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.submit-popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1001;
    overflow-y: auto;
}

.close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    padding: 0px;
    background-color: #41B06E;
    border: none;
    margin: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
}

.payment-popup-button {
    padding: 8px 16px;
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    font-weight: 500;
}

.exam-result-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.exam-title {
    font-size: 25px;
    font-weight: 600;
    color: #141E46;
}

.exam-result-text {
    font-size: 20px;
    font-weight: 500;
    color: #141E46;
    text-align: center;
    margin-top: 20px;
}

.exam-result-button {
    padding: 8px 16px;
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.exam-failure-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.exam-failure-text {
    font-size: 20px;
    font-weight: 500;
    color: #141E46;
    text-align: center;
}

.exam-failure-button {
    padding: 8px 16px;
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
}

.timer-up-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer-up-popup-overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.timer-up-popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1001;
    overflow-y: auto;
}

.exam-time-mobile {
    display: none;
}

@media screen and (max-width: 768px) {

    .exam-header {
        padding: 8px 5%;
        margin-bottom: 0px;
    }

    .exam-title {
        font-size: 18px;
    }

    .exam-time {
        font-size: 15px;
        display: none;
    }

    .exam-questions-attempted {
        font-size: 12px;
    }

    .exam-end-text {
        font-size: 12px;
    }

    .exam-end-icon {
        font-size: 12px;
    }

    .exam-questions-list {
        padding: 10px;
        width: 94%;
        margin-top: 10px;
    }

    .exam-question-text {
        font-size: 14px;
    }

    .exam-answer {
        margin-bottom: 10px;
    }

    .answer-label {
        font-size: 14px;
    }

    .exam-question-buttons-con {
        margin-top: 15px;
    }

    .exam-question-button {
        padding: 6px 12px;
        font-size: 13px;
    }

    .line {
        margin: 15px 0px;
    }

    .exam-failure-con {
        padding: 20px;
        width: 100%;
    }

    .exam-failure-text {
        font-size: 16px;
    }

    .exam-failure-button {
        padding: 6px 12px;
        font-size: 13px;
    }

    .timer-up-popup-content {
        padding: 15px;
        width: 90%;
    }

    .exam-result-con {
        padding: 20px;
        width: 90%;
    }

    .exam-result-text {
        font-size: 16px;
    }

    .exam-result-button {
        padding: 6px 12px;
        font-size: 13px;
    }

    .exam-time-mobile {
        display: block;
        font-size: 15px;
        color: #141E46;
        font-weight: 600;
        padding: 15px 0px;
        margin-bottom: 15px;
        background-color: #FFF5E0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #141E46;
    }
    
}
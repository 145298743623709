.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 6%;
  min-height: 90vh;
  margin-top: 10vh;
  width: 100%;
  background-color: #FFF7FC;
}

.home-heading {
    font-size: 21px;
    font-weight: 900;
    margin-bottom: 10px;
    color: #141E46;
    text-align: center;
    line-height: 1.7;
    width: 70%;
    word-spacing: 4px;
}

.home-span {
    color: #41B06E;
    padding-bottom: 4px;
    border-bottom: 3px solid #41B06E;
}

.faculty-heading {
    font-size: 28px;
    font-weight: 600;
    margin: 20px 0px;
    color: #141E46;
    align-self: flex-start;
    padding-bottom: 4px;
    border-bottom: 3px solid #41B06E;
}

.faculty-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
    width: 100%;
    padding-left: 0px;
    list-style: none;
    margin-top: 25px;
}

.faculty-item {
    width: 18%;
    border-radius: 10px;
    background-color: #8DECB430;
    border: 1px solid #41B06E;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.faculty-item:hover {
    box-shadow: 0px 0px 17px 0px #41B06E;
}

.faculty-image1 {
    width: 100%;
    height: 200px;
    user-select: none;
    border-radius: 10px 10px 0px 0px;
}

.faculty-content {
    padding: 5px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faculty-designation {
    font-size: 14px;
    font-weight: 600;
    color: #30ba68;
    margin-bottom: 5px;
    text-align: center;
}

.faculty-name {
    font-size: 16px;
    font-weight: 600;
    color: #141E46;
    margin-bottom: 5px;
    text-align: center;
}

/* .dashboard-text {
    font-size: 16px;
    color: #141E46;
    text-align: center;
} */

.dashboard-content {
    justify-content: center;
    margin-top: 30px;
    width: 40%;
}

.price-text {
    font-size: 16px;
    color: #141E46;
    margin-top: 10px;
    font-weight: 600;
}

.actual-price {
    font-size: 18px;
    font-weight: 600;
    color: #41B06E;
    margin-top: 10px;
    text-decoration: line-through;
    text-decoration-color: #141E46;
    margin-left: 5px;
}

.upi-image {
    width: 100%;
    height: auto;
    user-select: none;
}

.upi-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
}

.dashboard-upi-id {
    font-size: 16px;
    font-weight: 600;
    color: #41B06E;
}

.dashborad-upi-text {
    font-size: 16px;
    color: #141E46;
    margin-left: 10px;
    font-weight: 600;
}

.dashboard-text {
    font-size: 16px;
    color: #141E46;
    text-align: center;
    margin-top: 10px;
    line-height: 1.6;
}

.price {
    font-size: 16px;
    font-weight: 600;
    color: #141E46;
    margin-top: 10px;
    background-color: #8DECB435;
    border: 1px solid #41B06E;
    padding: 3px 6px;
    border-radius: 4px;
    margin: 0px 5px;
}

.home-button {
    padding: 10px 25px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #41B06E;
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .dashboard-content {
        width: 90%;
        margin-top: 10px;
    }

    .home-heading {
        font-size: 15px;
        text-align: center;
        margin-bottom: 0px;
        line-height: 1.5;
        width: 100%;
    }

    .home-text {
        font-size: 14px;
    }

    .faculty-heading {
        font-size: 20px;
    }

    .faculty-list {
        gap: 5%;
    }

    .faculty-item {
        width: 47%;
    }

    .faculty-image1 {
        height: 150px;
    }

    .price {
        font-size: 14px;
    }

    .dashboard-upi-id {
        font-size: 14px;
    }

    .dashborad-upi-text {
        font-size: 14px;
    }

    .upi-image {
        width: 100%;
    }

    .dashboard-container {
        padding: 15px 3%;
    }

    .home-button {
        /* width: 100%; */
    }
}
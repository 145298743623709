.home-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 90vh;
    background-color: #FFF5E010;
    margin-top: 10vh;
    /* padding: 0 5%; */
}

.home-page-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-page-content {
    width: 50%;
    /* padding-left: 5%; */
}

.home-page-image-container {
    width: 50%;
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../public/homepage-bg.jpg');
    opacity: 0.9;
}

.home-page-image-container::after {
    content: "";
    position: absolute;
    height: 100%;
    background: linear-gradient(to right, #FFF5E0, #ffffff00, #020B16);
}

.home-page-image {
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .home-page-section {
        flex-direction: column;
    }

    .home-page-content {
        width: 100%;
        padding: 0 5%;
    }

    .home-page-image-container {
        display: none;
    }

    .home-page-image {
        height: 50vh;
    }
    
}
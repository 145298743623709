.create-test-page-container {
    min-height: 90vh;
    margin-top: 10vh;
    padding: 20px 10%;
    padding-bottom: 40px;
    background-color: #FFF7FC;
}

.create-test-heading {
    font-size: 25px;
    margin-bottom: 20px;
    color: #41B06E;
}

.create-test-form {
    margin: 0px auto;
    width: 100%;
    max-width: 750px;
    border: 1px solid #41B06E;
    padding: 20px;
    border-radius: 10px;
}

.create-test-subheading {
    font-size: 20px;
    margin-bottom: 15px;
    color: #41B06E;
    text-align: center;
}

.create-test-form-group {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.create-test-label {
    width: 30%;
    font-size: 14px;
    color: #41B06E;
    font-weight: 600;
}

.create-test-input {
    width: 70%;
    padding: 0px 10px;
    border: 1px solid #41B06E;
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    background-color: transparent;
    outline: none;
}

.create-test-textarea {
    max-width: 70%;
    min-width: 70%;
    padding: 10px;
    border: 1px solid #41B06E;
    border-radius: 5px;
    font-size: 14px;
    background-color: transparent;
    outline: none;
    min-height: 85px;
    max-height: 200px;
}

.add-question-button {
    background-color: #41B06E;
    color: #FFF7FC;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px auto;
    margin-top: 20px;
}

.question-modal {
    background-color: #FFF7FC;
    border-radius: 10px;
    width: 50%;
    position: relative;
}

.close-question-popup {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: #41B06E;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 25px;
}

.question-error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.add-question-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.questions-list {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 20px auto;
}

.question-list-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #41B06E;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
    width: 100%;
}

.question-list-heading {
    font-size: 16px;
    color: #141E46;
    margin-bottom: 10px;
}

.question-list-text {
    font-size: 15px;
    margin-bottom: 10px;
    color: #141E46;
    margin-left: 15px;
}

.question-list-heading-span {
    font-weight: 600;
    color: #41B06E;
}

.question-list-text-span {
    font-weight: 600;
    color: #41B06E;
    margin-right: 5px;
}

.question-list-head-button-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-list-button-con {
    display: flex;
    align-items: center;
}

.question-list-button {
    background-color: #41B06E;
    color: #FFF7FC;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    display: block;
    margin-right: 10px;
}

@media screen and (max-width: 768px) {

    .create-test-page-container {
        padding: 14px 5%;
        padding-bottom: 25px;
    }

    .create-test-heading {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .create-test-form {
        padding: 10px;
        max-width: 100%;
    }

    .create-test-subheading {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .create-test-form-group {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .create-test-label {
        width: 100%;
        margin-bottom: 4px;
        font-size: 13px;
    }

    .create-test-input {
        width: 100%;
    }

    .create-test-textarea {
        max-width: 100%;
        min-width: 100%;
    }

    .add-question-button {
        margin: 0px;
    }

    .add-question-button-container {
        margin-top: 0px;
    }

    .question-modal {
        width: 92%;
    }

    .questions-list {
        width: 100%;
        margin-bottom: 10px;
    }

    .question-list-item {
        width: 100%;
        padding: 10px;
    }

    .question-list-head-button-con {
        flex-direction: column;
        align-items: flex-start;
    }

    .question-list-button-con {
        align-self: flex-end;
        margin-bottom: 10px;
    }

    .question-list-button {
        width: 100%;
    }

    .question-list-text {
        margin-left: 0px;
    }
    
}
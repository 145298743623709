.payment-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-popup-overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.payment-popup-content {
    position: relative;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 100%;
    margin-top: 15vh;
    max-height: 85vh;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    overflow-y: auto;
}

.payment-popup-content::-webkit-scrollbar {
    width: 6px;
}

.payment-popup-content::-webkit-scrollbar-thumb {
    background: #41B06E;
    border-radius: 6px;
}

.close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    padding: 0px;
    background-color: #41B06E;
    border: none;
    margin: 0px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px){
    .payment-popup-content {
        width: 95%;
        padding: 20px;
    }
}
.view-users-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    min-height: 90vh;
    padding: 25px 10%;
}

.user-heading {
    font-size: 30px;
    font-weight: 600;
    color: #41B06E;
    margin-bottom: 20px;
}

.user-view-table {
    width: 70%;
    margin-top: 25px;
    margin-bottom: 25px;
    overflow-y: auto;
}

.users-table {
    border-collapse: collapse;
    /* background: #ece3d3; */
    background: #8DECB440;
    width: 100%;
    border: 1px solid #41B06E;
}

.users-table-heading-row {
    background-color: #41B06E;
}

.users-table-data-row {
    border-top: 1px solid #41B06E;
}
.users-table-heading, .users-table-data {
    padding: 1rem;
    text-align: left;
    font-size: 15px;
}

.users-table-heading {
    font-weight: 600;
    background-color: #41B06E;
    color: #fff;
}

.users-table-data-row:nth-of-type(2n) {
    background: #8DECB480;
}

.user-view-search-filter-con {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.user-view-search-con {
    width: 40%;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #41B06E;
    border-radius: 4px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.user-view-filter-con {
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #EB6A4D;
    border-radius: 5px;
    margin-left: 10px;
    outline: none;
    padding-left: 10px;
}

.user-view-search-input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    padding-left: 0px;
    font-size: 15px;
    outline: none;
}

.user-view-search-button {
    height: 40px;
    background-color: transparent;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}

.search-icon {
    font-size: 20px;
    font-weight: bold;
    color: #41B06E;
}

.block-user-button {
    height: 35px;
    border: none;
    background-color: #41B06E;
    cursor: pointer;
    outline: none;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
}

.user-view-table-no-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    background-color: #8DECB440;
    border: 1px solid #41B06E;
}

.modal {
    background-color: #fcf9f2;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    position: relative;
}

.popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.modal-text {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}

.modal-close-button {
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    font-size: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
}

.popup-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
}

.job-details-upload-candidate-button {
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 15px;
}

.user-label {
    font-size: 13px;
    font-weight: 600;
    color: #41B06E;
    margin-bottom: 5px;
}

.user-input {
    width: 100%;
    height: 30px;
    border: 1px solid #41B06E;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 15px;
    outline: none;
    margin-bottom: 10px;
}

.modal-form {
    display: flex;
    flex-direction: column;
    width: 100%;

}

@media screen and (max-width: 768px) {
    .view-users-con {
        padding: 15px 5%;
    }

    .user-heading {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .user-view-search-filter-con {
        width: 100%;
    }

    .user-view-search-con {
        width: 100%;
        margin-right: 0px;
    }

    .user-view-table {
        width: 100%;
        margin: 10px 0px;
    }

    .users-table-heading {
        display: none;
    }

    .users-table-data {
        display: grid;
        grid-template-columns: 12ch auto;
        gap: .5rem;
        padding: 0.5rem 1rem;
    }

    .users-table-data:first-child {
        padding-top: 2rem;
    }

    .users-table-data:last-child {
        padding-bottom: 2rem;
    }

    .users-table-data::before {
        content: attr(data-cell) ": ";
        font-weight: 700;
        text-transform: capitalize;
    }
    
}
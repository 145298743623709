.admin-page-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 90vh;
    margin-top: 10vh;
    background-color: #fff;
    padding: 25px 10%;
}

.admin-page-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: flex-start;
}

.admin-page-bg {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-bg-img {
    width: 100%;
    height: 100%;
    user-select: none;
}

.admin-page-title {
    font-size: 25px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #141E46;
    text-align: center;
}

.admin-span {
    color: #41B06E;
    text-decoration: underline;
}

.admin-page-btn-con {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    flex-wrap: wrap;
}

.admin-page-btn {
    background-color: #8DECB420;
    border: 1px solid #41B06E;
    border-radius: 10px;
    text-decoration: none;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-right: 15px;
    width: 140px;
}

.admin-page-btn:hover {
    box-shadow: 0px 0px 10px 0px #41B06E;
}

.admin-page-icon {
    font-size: 80px;
    color: #41B06E;
}

.admin-page-btn-text {
    font-size: 15px;
    font-weight: 600;
    color: #41B06E;
    margin-top: 10px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .admin-page-con {
        padding: 0px 5%;
    }

    .admin-page-content {
        width: 100%;
    }

    .admin-page-title {
        font-size: 20px;
        margin-top: 15px;
    }

    .admin-page-bg {
        display: none;
    }

    .admin-page-btn-con {
        justify-content: space-between;
        margin-top: 0px;
    }

    .admin-page-icon {
        font-size: 65px;
    }

    .admin-page-btn-text {
        font-size: 13px;
    }

    .admin-page-btn {
        width: 47.5%;
        margin-right: 0px;
    }
    
}
.login-form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #8DECB410;
    border-radius: 10px;
    border: 1px solid #41B06E;
}

.login-form-heading {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #41B06E;
    text-align: center;
}

.login-form-label {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #41B06E;
}

.login-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 13px;
    border: 1px solid #41B06E;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
}

.login-form-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #41B06E;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.login-form-button:hover {
    background-color: #8DECB4;
}

.login-form-text {
    font-size: 13px;
    color: #41B06E;
    text-align: center;
}

.error-message {
    font-size: 13px;
    color: #ff2db6;
    margin-bottom: 10px;
}

.login-form-link {
    color: #141E46;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .login-form {
        width: 100%;
    }

    .login-form-heading {
        font-size: 20px;
    }

    .login-form-label {
        font-size: 10px;
    }

    .login-form-input {
        padding: 8px;
        margin-bottom: 10px;
    }

    .login-form-button {
        padding: 8px;
        margin-bottom: 10px;
        font-size: 13px;
    }

    .login-form-text {
        font-size: 11px;
    }

    .error-message {
        font-size: 11px;
    }

    .login-form-link {
        font-size: 13px;
    }
}
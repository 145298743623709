.admin-test-page-content {
    display: none;
}

.add-test-button {
    background-color: #41B06E;
    color: #FFF7FC;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 15px;
}

.admin-test-page-heading {
    display: block;
}

@media screen and (max-width: 768px) {
    .admin-test-page-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }

    .add-test-button {
        font-size: 13px;
        padding: 6px 10px;
        margin-left: 0px;
    }

    .admin-test-page-heading {
        display: none;
    }
    
}
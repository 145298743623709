.footer {
    background-color: #ccf2dc;
    padding: 20px 10%;
    text-align: center;
    width: 100%;
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-text {
    font-size: 14px;
    color: #141E46;
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 20px 5%;
    }

    .footer-text {
        font-size: 12px;
    }
    
}
.control-homepage-con {
    min-height: 90vh;
    margin-top: 10vh;
    padding: 25px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faculty-image {
    width: 170px;
    height: 180px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.faculty-buttons-con {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.faculty-button {
    background-color: #41B06E;
    color: #FFF7FC;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    display: block;
    width: fit-content;
    margin-right: 10px;
}

.faculty-popup {
    width: 100%!important;
    display: flex;
    flex-direction: column;
}

.modal {
    width: 40%;
    display: flex;
    flex-direction: column;
    background-color: #FFF7FC;
}

.faculty-popup-heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #41B06E;
    text-align: center;
}

.faculty-popup-form {
    display: flex;
    flex-direction: column;
}

.faculty-popup-label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #41B06E;
}

.faculty-popup-input {
    padding: 8px;
    border: 1px solid #41B06E;
    border-radius: 5px;
    margin-bottom: 12px;
    background-color: transparent;
    outline: none;
}

.faculty-popup-button {
    background-color: #41B06E;
    color: #FFF7FC;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    display: block;
    width: fit-content;
    margin-top: 10px;
    align-self: flex-end;
}

.facutly-sub-btn {
    margin-right: 10px;
}

.faculty-popup-close {
    background-color: #41B06E;
    color: #FFF7FC;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -10px;
}

.faculty-popup-error {
    color: red;
    font-size: 13px;
    margin-bottom: 10px;
}

.faculty-head-con {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

}

.faculty-head-buttons-con {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .control-homepage-con {
        padding: 15px 5%;
    }

    .faculty-item2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        padding: 0px;
    }

    .faculty-details {
        padding: 15px;
    }

    .faculty-image {
        width: 100%;
        height: 200px;
        border-radius: 10px 10px 0 0;
    }

    .faculty-button {
        padding: 6px 12px;
    }

    .faculty-popup-heading {
        margin-bottom: 10px;
    }

    .modal {
        width: 90%;
        padding: 15px;
    }

    .faculty-popup-heading {
        font-size: 18px;
    }

    .faculty-popup-label {
        font-size: 13px;
    }

    .faculty-popup-button {
        padding: 6px 12px;
        font-size: 13px;
    }

    .facutly-sub-btn {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .faculty-head-buttons-con {
        flex-direction: column;
    }
    
}
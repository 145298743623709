.test-details-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 10%;
    margin-top: 10vh;
    min-height: 90vh;
}

.test-details-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #8DECB415;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #41B06E;
    width: 100%;
    max-width: 650px;
}

.test-details-page-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #41B06E;
}

.test-details-page-info {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.test-details-page-info-title {
    font-size: 17px;
    font-weight: 600;
    color: #41B06E;
}

.test-details-page-info-text {
    font-size: 17px;
    color: #141E46;
    margin-left: 10px;
    flex-wrap: wrap;
}

.test-details-page-start-button {
    background-color: #41B06E;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {

    .test-details-page {
        padding: 15px 5%;
    }

    .test-details-page-content {
        padding: 15px;
        width: 100%;
    }

    .test-details-page-title {
        font-size: 20px;
    }

    .test-details-page-info {
        margin-bottom: 10px;
    }

    .test-details-page-info-title {
        font-size: 14px;
    }

    .test-details-page-info-text {
        font-size: 14px;
    }

    .test-details-page-start-button {
        font-size: 15px;
        width: 100%;
    }
    
}
.submissions-page {
    margin-top: 10vh;
    min-height: 90vh;
    padding: 25px 10%;
    background-color: #FFF7FC;
    display: flex;
    flex-direction: column;
}

.submission-heading {
    font-size: 25px;
    font-weight: 700;
    color: #141E46;
    margin-bottom: 20px;
}

.submissions-list {
    list-style: none;
    padding: 0;
}

.submission-item {
    background-color: #8DECB415;
    border: 1px solid #41B06E;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.submission-item:hover {
    background-color: #8DECB435;
}

.submission-image {
    width: 175px;
    height: 222px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.submission-details {
    padding: 20px;
}

.test-name {
    font-size: 20px;
    font-weight: 700;
    color: #41B06E;
    margin-bottom: 10px;
}

.test-duration {
    font-size: 15px;
    color: #141E46;
    margin-bottom: 10px;
    font-weight: 600;
}

.submission-span {
    margin-right: 5px;
    color: #41B06E;
}

.submission-button {
    background-color: #41B06E;
    color: #FFF7FC;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    margin-top: 10px;
    text-decoration: none;
    display: block;
    width: fit-content;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

}

.exam-failure-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    align-self: center;
}

.exam-failure-text {
    font-size: 20px;
    font-weight: 500;
    color: #141E46;
}

.exam-failure-button {
    padding: 8px 16px;
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
}

.no-submissions-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background: #faf4e7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    align-self: center;
}

.no-submissions-text {
    font-size: 20px;
    font-weight: 500;
    color: #141E46;
    text-align: center;
}

.no-submissions-button {
    padding: 8px 16px;
    background-color: #41B06E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .submissions-page {
        padding: 15px 5%;
    }

    .submission-heading {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .submission-item {
        flex-direction: column;
        padding: 15px;
        align-items: flex-start;
    }

    .submission-image {
        display: none;
    }

    .submission-details {
        padding: 0px;
        width: 100%;
    }

    .test-name {
        font-size: 18px;
    }

    .test-duration {
        font-size: 13px;
    }

    .submission-button {
        font-size: 13px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .no-submissions-con {
        padding: 20px;
        width: 100%;
    }

    .no-submissions-text {
        font-size: 16px;
    }

    .no-submissions-button {
        font-size: 13px;
    }
    
}
.test-page-container {
    margin-top: 10vh;
    width: 100%;
    min-height: 90vh;
    background-color: #FFF7FC;
    padding: 25px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.test-page-header {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #41B06E;
    padding-bottom: 10px;
}

.test-page-header-right {
    display: flex;
    align-items: center;
}

.tests-heading {
    font-size: 24px;
    font-weight: 600;
    color: #41B06E;
    align-self: flex-start;
}

.test-page-header-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    color: #41B06E;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    margin-left: 10px;
}

.test-page-header-button:hover {
    background-color: #8DECB430;
}

.active-test-page-header-button {
    background-color: #8DECB450;
    font-weight: 600;
}

.tests-list {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    width: 100%;
}

.test-list-item {
    display: flex;
    align-items: center;
    background-color: #8DECB415;
    border-radius: 10px;
    border: 1px solid #41B06E;
    margin-bottom: 20px;
    width: 100%;
}

.test-list-item:hover {
    background-color: #8DECB430;
}

.test-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.test-list-details {
    padding: 15px 20px;

}

.test-list-heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #41B06E;
}

.test-list-text {
    font-size: 15px;
    line-height: 1.7;
    color: #141E46;
}

.test-list-duration {
    font-size: 15px;
    font-weight: 600;
    color: #41B06E;
    margin-right: 15px;
    width: 200px;
}

.test-span {
    font-weight: 600;
    color: #141E46;
}

.test-list-tags {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.test-list-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #41B06E;
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .test-page-container {
        padding: 15px 5%;
    }

    .test-list-heading {
        font-size: 18px;
        margin-bottom: 6px;
    }

    .tests-list {
        margin-top: 10px;
    }

    .test-list-item {
        margin-bottom: 15px;
    }

    .test-list-text {
        font-size: 13px;
        margin-bottom: 6px;
        line-height: 1.4;
    }

    .test-list-tags {
        align-items: flex-start;
        flex-direction: column;
        margin: 0px;
    }

    .test-image {
        width: 100%;
        min-height: 100%;
        border-radius: 10px 10px 0 0;
    }

    .test-list-details {
        width: 100%;
        padding: 10px;
    }

    .test-list-duration {
        width: 100%;
        margin-bottom: 10px;
        font-size: 13px;
    }

    .tests-heading {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .test-list-button {
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }

    .test-page-header {
        padding-bottom: 5px;
        flex-direction: column;
    }

    .test-page-header-right {
        justify-content: space-between;
        width: 100%;
    }

    .test-page-header-button {
        margin-left: 0;
        padding: 6px;
        font-size: 14px;
    }
    
}